import React, { Fragment } from 'react';
import { Tag, Tabs, } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { routerMap } from '@/router/agent_router';
import ActivityList from './ActivityList';
import ActivityRule from './ActivityRule';
import { Button, Layout } from '@/components';
import { LibAuthCode } from 'lib/Auth';

interface IActivityProps {
  activityType: IActivityType | 'GIFT_PRODUCT';
  activityTabName?: string;
  productTabName?: string;
  history: {
    push(path: string): void
  };
}

export default class ActivityTabs extends React.PureComponent<IActivityProps> {
  
  private activeKey: string = '1'
  private list = React.createRef<ActivityList>()
  private rule = React.createRef<ActivityRule>()
  private gift = React.createRef<ActivityRule>()
  private specialDtl = React.createRef<ActivityRule>()

  private get getActivityTypeDesc() {
    if (this.props.activityType === 'DISCOUNT') return '单品打折'
    if (this.props.activityType === 'SPECIAL') return '单品特价'
    if (this.props.activityType === 'GIFT') return '单品满赠'
    if (this.props.activityType === 'TRIAL') return '单品试用'
  }
  render() {

    return <Tabs className="app-tabs" onChange={key => this.activeKey = key} animated={false} tabBarExtraContent={
      <Layout.TabBarExtra>
        <Button authCode={LibAuthCode.activity.create} style={{marginLeft: 20}} icon={<PlusOutlined/>} type="primary" onClick={() => {
          if (this.props.activityType === 'DISCOUNT') {
            this.props.history.push(routerMap['activity.discount.create'].path)
          }
          if (this.props.activityType === 'SPECIAL') {
            this.props.history.push(routerMap['activity.special.create'].path)
          }
          if (this.props.activityType === 'GIFT') {
            this.props.history.push(routerMap['activity.gift.create'].path)
          }
        }}>新增</Button>
        <Button.Refresh  onClick={() => {
          if (this.activeKey === '1') {
            this.list.current?.refresh();
          } else if (this.activeKey === '2') {
            this.rule.current?.refresh();
          } else if (this.activeKey === '3') {
            this.specialDtl.current?.refresh();
          } else {
            this.gift.current?.refresh();
          }
        }}></Button.Refresh>
      </Layout.TabBarExtra>
    }>
      <Tabs.TabPane key="1" tab={this.props.activityTabName || this.getActivityTypeDesc}>
        <ActivityList ref={this.list} activityType={this.props.activityType as IActivityType} goDetail={row => {
          if (this.props.activityType === 'DISCOUNT') {
            this.props.history.push(routerMap['activity.discount.detail'].getPath(row.activityId));
          }
          if (this.props.activityType === 'SPECIAL') {
            this.props.history.push(routerMap['activity.special.detail'].getPath(row.activityId));
          }
          if (this.props.activityType === 'GIFT') {
            this.props.history.push(routerMap['activity.gift.detail'].getPath(row.activityId));
          }
        }}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab={this.props.productTabName || this.getActivityTypeDesc + '规则'}>
        <ActivityRule ref={this.rule} activityType={this.props.activityType}/>
      </Tabs.TabPane>
      {
        this.props.activityType === 'SPECIAL' &&
        <Tabs.TabPane key="3" tab="商品规格特价明细">
          <ActivityRule ref={this.specialDtl} activityType="SPECIAL_DTL"></ActivityRule>
        </Tabs.TabPane>
      }
      {
        this.props.activityType === 'GIFT' &&
        <Tabs.TabPane key="4" tab="赠品明细">
          <ActivityRule ref={this.gift} activityType="GIFT_PRODUCT"></ActivityRule>
        </Tabs.TabPane>
      }
    </Tabs>
  }
}
