import React, { Fragment } from 'react';
import { Tag } from 'antd';
import { ViewTable, Layout } from '@/components';
import apis from '@/api/api';
import Filter, { IFilterField } from '@/components/filter/Filter';

export default class ActivityRule extends React.PureComponent<{
  activityType: IActivityType | 'GIFT_PRODUCT' | 'SPECIAL_DTL'
}> {
  
  private table = React.createRef<ViewTable>()

  queryParams = {
    activityType: this.props.activityType,
    productName: undefined,
    activityName: undefined,
    giftProductName: undefined,
    categoryId: undefined,
  }

  findAll = () => {
    this.table.current?.reset(this.queryParams);
  };

  public refresh = () => {
    this.table.current?.refresh();
  }

  getColumns = (): any[] => {
    if (this.props.activityType === 'DISCOUNT') return this.getDiscountColumns();
    if (this.props.activityType === 'SPECIAL') return this.getSpecialColumns();
    if (this.props.activityType === 'SPECIAL_DTL') return this.getSpecialDtlColumns(); 
    if (this.props.activityType === 'GIFT') return this.getGiftColumns();
    if (this.props.activityType === 'GIFT_PRODUCT') return this.getGiftProductColumns();
    return [];
  }

  private getDiscountColumns = () => {
    return [
      {
        title: '打折',
        dataIndex: 'discount',
        width: 100,
        align: 'right',
        render: (value) => value || '--'
      },
    ];
  }

  private getSpecialColumns = () => {
    return [
      {
        title: '规格',
        dataIndex: 'skuName',
        width: 200,
        render: (value) => value || '--'
      },
    ];
  }

  private getSpecialDtlColumns = () => {
    return [
      {
        title: '规格',
        dataIndex: 'skuName',
        width: 200,
        render: (value) => value || '--'
      },
      {
        title: '原价',
        dataIndex: 'originalPrice',
        width: 100,
        align: 'right',
        render: (value) => value || '--'
      },
      {
        title: '特价',
        dataIndex: 'price',
        width: 100,
        align: 'right',
        render: (value) => value || '--'
      },
    ];
  }

  private getGiftColumns = () => {
    return [
      {
        title: '满赠规则',
        dataIndex: 'giftConditionDesc',
        align: 'center',
        width: 100,
      },
      {
        title: '赠送方式',
        dataIndex: 'giftMethodDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '赠品规则',
        dataIndex: 'giftRuleDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '计算规则',
        dataIndex: 'giftAlgorithmDesc',
        width: 100,
        align: 'center',
      },
      {
        title: '赠品数量(款)',
        dataIndex: 'giftProductCount',
        width: 100,
        align: 'right',
      },
    ];
  }

  private getGiftProductColumns = () => {
    return [
      {
        title: '赠送规则',
        dataIndex: 'giftDesc',
        width: 300,
      },
      {
        title: '赠品',
        dataIndex: 'giftProductName',
        width: 200,
      },
      {
        title: '赠品规格',
        dataIndex: 'giftSkuName',
        width: 200,
      },
    ];
  }

  render() {

    const fields: IFilterField[] = [];

    if (this.props.activityType !== 'GIFT_PRODUCT') {
      fields.push({
        type: 'input',
        name: '活动名称',
        field: 'activityName'
      });
    }

    fields.push({
      type: 'input',
      name: '商品名称',
      field: 'productName'
    });

    if (this.props.activityType === 'GIFT_PRODUCT') {
      fields.push({
        type: 'input',
        name: '赠品名称',
        field: 'giftProductName'
      });
    }


    return (
      <Layout.TabPage>
        <Filter
          fields={fields}
          onChange={(values: any) => {
            this.queryParams.productName = values.productName;
            this.queryParams.giftProductName = values.giftProductName;
            this.queryParams.activityName = values.activityName;
            this.findAll();
          }}
        />

        <ViewTable
          flex1
          ref={this.table}
          fetchOption={{
            api: {
              SPECIAL_DTL: apis.activity_special_dtl_fac,
              GIFT_PRODUCT: apis['activity.gift.findAndCount'],
            }[this.props.activityType] || apis.activity_rule_fac,
            data: this.props.activityType === 'SPECIAL_DTL' ? {} : {
              activityType: this.queryParams.activityType
            },
          }}
          columns={[
            {
              title: '活动名称',
              dataIndex: 'activityName',
              width: 200,
            },
            {
              title: '活动状态',
              width: 100,
              align: 'center',
              dataIndex: 'activityStatusDesc',
              render: (value, row) => {
                if (row.activityStatus === 'DONE') return <Tag color="#f2f4f5" style={{color: '#666'}}>{value}</Tag>
                if (row.activityStatus === 'WAITING') return <Tag color="#108ee9">{value}</Tag>
                if (row.activityStatus === 'ONGOING') return <Tag color="#87d068">{value}</Tag>
              },
            },
            {
              title: '商品分类',
              dataIndex: 'categoryName',
              width: 100,
            },
            {
              title: '商品名称',
              dataIndex: 'productName',
              width: 200,
            },
            {
              title: '活动类型',
              dataIndex: 'activityTypeDesc',
              align: 'center',
              width: 100,
            },
            ...this.getColumns(),
            {
              title: '开始时间',
              align: 'center',
              dataIndex: 'startDatetime',
              width: 200
            },
            {
              title: '结束时间',
              align: 'center',
              dataIndex: 'endDatetime',
              width: 200
            },
          ]}
          rowKey={row => row.id}/>
      </Layout.TabPage>
    );
  }
}
