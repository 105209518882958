import React, { Fragment } from 'react';
import { Select, Tag, } from 'antd';
import { Dialog, SearchForm, ViewTable, Layout, Filter } from '@/components';
import dayjs from 'dayjs';
import apis from '@/api/api';

export default class ActivityDiscountList extends React.PureComponent<{
  activityType: IActivityType
  goDetail(row: any): void
}> {
  
  private table = React.createRef<ViewTable>()

  private queryParams = {
    activityType: this.props.activityType,
    keyword: '',
  }

  findAll = async () => {
    this.table.current?.reset(this.queryParams);
  };

  public refresh = () => {
    this.table.current?.refresh();
  };

  render() {

    const render = (value, row) => {
      if (row.status === '13') {
        return <span style={{color: '#C8D1D9'}}>{value}</span>;
      }
      return value;
    }

    return (
      <Layout.TabPage>
        <Filter
          fields={[
            {
              type: 'input',
              name: '活动名称',
              field: 'keyword'
            },
          ]}
          onChange={(values: any) => {
            this.queryParams.keyword = values.keyword;
            this.findAll();
          }}
        />
        
        <ViewTable
          flex1
          ref={this.table}
          columns={[
            {
              title: '活动编号',
              dataIndex: 'activityId',
              width: 200,
              align: 'center',
              render,
            },
            {
              title: '活动名称',
              dataIndex: 'activityName',
              width: 240,
            },
            {
              title: '活动状态',
              width: 100,
              align: 'center',
              dataIndex: 'activityStatusDesc',
              render: (value, row) => {
                if (row.activityStatus === 'DONE') return <Tag color="#f2f4f5" style={{color: '#666'}}>{value}</Tag>
                if (row.activityStatus === 'WAITING') return <Tag color="#108ee9">{value}</Tag>
                if (row.activityStatus === 'ONGOING') return <Tag color="#87d068">{value}</Tag>
              },
            },
            {
              title: '活动类型',
              dataIndex: 'activityTypeDesc',
              width: 100,
              align: 'center'
            },
            {
              title: '活动规则',
              dataIndex: 'productCount',
              width: 100,
              render: v => v + ' 款参与'
            },
            // {
            //   title: '活动内容',
            //   dataIndex: 'activityContent',
            //   width: 240,
            //   render,
            // },
            {
              title: '开始时间',
              align: 'center',
              dataIndex: 'startDatetime',
              width: 200,
              render
            },
            {
              title: '结束时间',
              align: 'center',
              dataIndex: 'endDatetime',
              width: 200,
              render,
            },
            {
              title: '创建时间',
              align: 'center',
              dataIndex: 'createdAt',
              width: 200,
              render
            },
          ]}
          fetchOption={{
            api: apis.activity_fac,
            data: {
              activityType: this.queryParams.activityType
            },
          }}
          actionButtons={[
            {
              type: 'detail',
              onClick: this.props.goDetail
            },
            {
              type: 'done',
              tip: '结束',
              disabled: row => row.activityStatus === 'DONE',
              onClick: (row) => {
                this.clickDone(row);
              }
            },
          ]}
          rowKey={row => row.id}/>
      </Layout.TabPage>
    );
  }

  // 结束
  clickDone = row => {
    Dialog.confirm({
      title: '结束确认',
      content: `结束活动后无法恢复，确定结束活动“${row.activityName}”吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        
        apis['activity.update'].post({
          activityId: row.id,
          endDatetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          rules: [],
        })
        .then(() => {
          showSuccess.save();
          this.table.current?.refresh();
        })
      }
    });
  };
}
